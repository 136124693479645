import Vue from 'vue'
import VueRouter from 'vue-router'

/*基本模块*/
const Login = () => import( './components/Login.vue')
const Home = () => import('./components/Home.vue')
const Welcome = () => import( './components/Welcome.vue')
const Error = () => import('./components/Error.vue')
const ResetPwd = () => import('./components/ResetPwd.vue')
/*用户管理*/
const Password = () => import('./components/users/Password.vue')
/*运营管理*/
const UserManage = () => import('./components/manage/userManage.vue')
const TeachCheck = () => import('./components/manage/teachCheck.vue')
const ArenaManage = () => import('./components/manage/arenaManage.vue')
const SignArena = () => import('./components/manage/signArena.vue')
const Coupon = () => import('./components/manage/coupon.vue')
const Recomm = () => import('./components/manage/recommManage.vue')
const Order = () => import('./components/manage/orderManage.vue')
/*报表*/
const Statistics = () => import('./components/report/statistics.vue')
/*基础设置*/
const PriceLevel = () => import('./components/baseSet/priceLevel.vue')
const PlayManage = () => import('./components/baseSet/playManage.vue')
const TripCost = () => import('./components/baseSet/tripCost.vue')
const AreaManage = () => import('./components/baseSet/areaManage.vue')
const PlayItem = () => import('./components/baseSet/playItem.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/resetPwd',
    component: ResetPwd
  },
  {
    path: '/error',
    component: Error
  },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
        { path: '/welcome', component: Welcome },
        { path: '/password', component: Password },

        { path: '/userManage', component: UserManage },
        { path: '/teachCheck', component: TeachCheck },
        { path: '/arenaManage', component: ArenaManage },
        { path: '/signArena', component: SignArena },
        { path: '/coupon', component: Coupon },
        { path: '/recomm', component: Recomm },
        { path: '/order', component: Order },

        { path: '/statistics', component: Statistics },

        { path: '/priceLevel', component: PriceLevel },
        { path: '/playManage', component: PlayManage },
        { path: '/tripCost', component: TripCost },
        { path: '/areaManage', component: AreaManage },
        { path: '/playItem', component: PlayItem },

    ]
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫：无token跳转登录页
router.beforeEach((to, from, next) => {
  // to将要访问的路径 from 从哪个路径跳转而来 next 是一个函数，代表放行
  // next()放行 next('/login')强制跳转指定页面

    return next()
  // if (to.path === '/login' || to.path === '/error' || to.path === '/resetPwd') {
  //   return next()
  // } else {
  //   const tokenStr = window.localStorage.getItem('token')
  //   if (!tokenStr) {
  //     console.log('没有token')
  //     return next('/login') // 无token强制跳转登录页
  //   } else {
  //     console.log('有token')
  //     return next()
  //   }
  // }
})

export default router
