import Vue from 'vue'
import App from './App.vue'
import router from './index'
import './plugins/element.js'
// 导入全局样式表
import './assets/global.css'
// 导入axios
import axios from 'axios'
// 导入富文本编辑区
import VueQuillEditor from 'vue-quill-editor'
// 导入富文本编辑器样式
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
// 导入进度条Nprogress包对应的js和样式
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 配置请求根路径
axios.defaults.baseURL = '/api'

// 请求头增加token
// 在request的拦截器中，展示进度条：NProgress.start()
axios.interceptors.request.use(config => {
    NProgress.start()
    console.log('添加token')
    config.headers.Authorization = window.localStorage.getItem('token')
    return config
})

// 在response拦截器中，隐藏进度条：NProgress.done()
axios.interceptors.response.use(config => {
    NProgress.done()
    return config
},err => {
    if (err && err.response) {
        switch (err.response.status) {
            case 400: err.message = '请求错误(400)'; break;
            case 403: err.message = '拒绝访问(403)'; break;
            case 404: err.message = '请求出错(404)'; break;
            case 408: err.message = '请求超时(408)'; break;
            case 500: err.message = '服务器错误(500)'; break;
            case 501: err.message = '服务未实现(501)'; break;
            case 502: err.message = '网络错误(502)'; break;
            case 503: err.message = '服务不可用(503)'; break;
            case 504: err.message = '网络超时(504)'; break;
            case 505: err.message = 'HTTP版本不受支持(505)'; break;
            default: err.message = `连接出错(${err.response.status})!`;
        }

        //router.push({path:'/error', query:{errorCode: '500'}});
    } else {
        err.message = '连接服务器失败!'
    }
    return Promise.reject(err.message);
})
// 将axios配置到vue的全局属性中，命名为$http
Vue.prototype.$http = axios

Vue.config.productionTip = false
// 将富文本编辑器注册为全局可用组件
Vue.use(VueQuillEditor)

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
